import React, { useState } from 'react'
import ExcelJS, { Workbook } from 'exceljs';
import { Link } from 'react-router-dom';

const Home = () => {
  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);

  const handleFileChange1 = async (event) => {
    const file = event.target.files[0];
    setSelectedFile1(file);
  };

  const handleFileChange2 = async (event) => {
    const file = event.target.files[0];
    setSelectedFile2(file);
  };

        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //                                                 주문서 변환                                                //
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const orderSheet = () => {
    if (!selectedFile1) {
      console.error('파일을 선택하세요.');
      alert('파일을 선택하세요.');
      return;
    }

    const originalWorkbook = new ExcelJS.Workbook();
    const reader = new FileReader();

    reader.onload = (e) => {
      const buffer = e.target.result;
      const data = new Uint8Array(buffer);
      originalWorkbook.xlsx.load(data).then(() => {

        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //                                      엑셀 파일에서 필요한 부분만 얻기                                      //
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        // 첫 번째 시트를 가져옴
        const worksheet = originalWorkbook.getWorksheet(1);

        // 필터를 해제
        worksheet.autoFilter = null

        // 1 행을 삭제
        worksheet.spliceRows(1, 1);

        // 필요한 열 제목들
        const requiredColumns = ["수취인명", "상품명", "옵션정보", "수량"];

        // 열 제목을 가져옴
        let headerRow = worksheet.getRow(1);
        let columnNames = headerRow.values;

        // 역순으로 반복문을 수행하여 열 삭제
        for (let columnIndex = columnNames.length; columnIndex >= 1; columnIndex--) {
          const columnName = columnNames[columnIndex];
          
          if (!requiredColumns.includes(columnName)) {
            worksheet.spliceColumns(columnIndex, 1);
          }
        }

        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //                                        가공한 파일을 JSON으로 변환                                         //
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        const jsonData = [];
        
        let headers; // 헤더 변수 선언

        // 행을 처리 (2행부터)
        worksheet.eachRow({ includeEmpty: false }, (row, rowIndex) => {

          headers = ['id', 'name', 'productName', 'option', 'quantity'];

          if (rowIndex !== 1) {
            const rowData = {};
            rowData[headers[0]] = ''
            row.eachCell({ includeEmpty: false }, (cell, colNumber) => {
              const header = headers[colNumber];
              rowData[header] = cell.value;
            });
            jsonData.push(rowData);
          }
        });

        // 'option' 키 불필요한 내용 제거
        jsonData.forEach((data) => {
          if (data.option.includes(':')) {
            // 콜론(:)이 있는 경우에만 수정
            const optionParts = data.option.split(': ');
            data.option = optionParts.length > 1 ? optionParts[1].trim() : data.option;
          }
        });

        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //                                           새로운 엑셀 파일 작성                                            //
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        const workbook = new Workbook();
        const sheet = workbook.addWorksheet();

        // 날짜 형식
        const today = new Date();

        const year = today.getFullYear().toString().slice(-2); // 연도의 마지막 2자리
        const month = (today.getMonth() + 1).toString().padStart(2, '0'); // 월 (0부터 시작하므로 +1, 두 자리로 표시)
        const day = today.getDate().toString().padStart(2, '0'); // 일 (두 자리로 표시)

        const dayOfWeek = ['일', '월', '화', '수', '목', '금', '토'][today.getDay()]; // 요일

        const formattedDate = `${year}-${month}-${day} ${dayOfWeek}`;

        sheet.mergeCells('A1:E1');
        sheet.getCell('A1').value = `스마트스토어 주문서 (${formattedDate})`;
        sheet.getCell('A1').font = { size: 15, bold: true };
        sheet.getCell('A1').alignment = { horizontal: 'center' };

        // Header 설정
        sheet.getRow(2).values = ['No.', '수령인', '상품', '옵션', '수량'];
        for (let col = 1; col <= 5; col++) {
          const cell = sheet.getCell(2, col);
          cell.font = { bold: true };
          cell.alignment = { horizontal: 'center' };
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'BFBFBF' },
          };
        }

        sheet.columns = [
          { key: 'id', width: 5},
          { key: 'name', width: 10},
          { key: 'productName', width: 50},
          { key: 'option', width: 25},
          { key: 'quantity', width: 5},
        ]

        sheet.addRows(jsonData);

        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //                                          셀 배경색 & No 추가 & 정렬                                        //
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        const colorArray = ['C5D9F1', 'DCE6F1', 'F2DCDB', 'EBF1DE', 'E4DFEC', 'DAEEF3', 'FDE9D9'];
        let colorIndex = 0;
        let i = 1;

        // 헤더 행에서 '수령인' 열의 인덱스 찾기
        headers = sheet.getRow(2).values;
        let recipientColumnIndex = headers.indexOf('수령인');

        let distinctValuesMap = new Map();

        // 행을 확인하면서 중복된 값의 위치를 찾아 배경 색상을 적용
        sheet.eachRow({ includeEmpty: true, skipHeader: true }, (row, rowNumber) => {
          if (rowNumber < 3) {
            return;
          }

          const currentCell = row.getCell(recipientColumnIndex);
          const previousRowNumber = row.number - 1;
          const nextRowNumber = row.number + 1;
          const previousRow = sheet.getRow(previousRowNumber);
          const nextRow = sheet.getRow(nextRowNumber);
          const previousCell = previousRow.getCell(recipientColumnIndex);
          const nextCell = nextRow.getCell(recipientColumnIndex);

          // 중복되지 않은 행은 SKIP
          if (currentCell.value !== nextCell.value && currentCell.value !== previousCell.value) {
            row.getCell(1).value = i
            i++;
            return;
          }

          if (currentCell.value) {
            if (!distinctValuesMap.has(currentCell.value)) {
              row.getCell(1).value = i
              i++;
              // 중복된 값이 처음 나타나는 경우에만 색상을 설정
              distinctValuesMap.set(currentCell.value, colorArray[colorIndex]);

              const dataColumns = row.actualCellCount;
              for (let col = 1; col <= dataColumns; col++) {
                const currentCell = row.getCell(col);
                
                currentCell.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: colorArray[colorIndex] }, // 배경색 적용
                };
              }

              // 색상 인덱스를 다음으로 이동
              colorIndex = (colorIndex + 1) % colorArray.length;
            } else {
              row.getCell(1).value = previousRow.getCell(1).value
              // 이미 등록된 중복된 값이면 해당 색상을 가져와서 적용

              const dataColumns = row.actualCellCount;
              for (let col = 1; col <= dataColumns; col++) {
                const currentCell = row.getCell(col);
                
                currentCell.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: distinctValuesMap.get(row.getCell(recipientColumnIndex).value) }, // 배경색 적용
                };
              }


            }
          }
        });

        ['A', 'B', 'E', 'H'].forEach((column) => {
          sheet.getColumn(column).alignment = { horizontal: 'center' };
        });

        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //                                              주문서 수량 합산표                                            //
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        sheet.mergeCells('G1:H1');
        sheet.getCell('G1').value = `주문서 수량 합산표 (${formattedDate})`;
        sheet.getCell('G1').font = { size: 13, bold: true };
        sheet.getCell('G1').alignment = { horizontal: 'center' };

        sheet.getCell('G2').value = '옵션'
        sheet.getCell('H2').value = '수량'
        
        // 열 너비
        sheet.getColumn('G').width = 32;
        sheet.getColumn('H').width = 8;

        for (let col = 7; col <= 8; col++) {
          const cell = sheet.getCell(2, col);
          cell.font = { bold: true };
          cell.alignment = { horizontal: 'center' };
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'BFBFBF' },
          };
        }

        // 'option'을 기준으로 데이터 그룹핑
        const groupedData = jsonData.reduce((acc, data) => {
          const key = data.option;

          // 이미 해당 'option'으로 그룹이 존재하는지 확인
          if (!acc[key]) {
            acc[key] = { option: key, totalQuantity: 0 };
          }

          // 'quantity' 누적 합산
          acc[key].totalQuantity += data.quantity;

          return acc;
        }, {});

        // 'option' 이름에 '들기름' 또는 '참기름'이 포함되고 '중국산'이 포함되지않은 항목을 위쪽으로 정렬
        const sortedGroupedData = Object.values(groupedData).sort((a, b) => {
          const aHasSesameOil = (a.option.includes('들기름') || a.option.includes('참기름')) && !a.option.includes('중국산');
          const bHasSesameOil = (b.option.includes('들기름') || b.option.includes('참기름')) && !b.option.includes('중국산');
          
          // '들기름' 또는 '참기름'이 포함된 항목을 위쪽으로 올리기
          if (aHasSesameOil && !bHasSesameOil) {
            return -1;
          } else if (!aHasSesameOil && bHasSesameOil) {
            return 1;
          }

          // '선물세트'가 포함된 항목을 위쪽으로 올리기 

          if (a.option.includes('선물세트') && !b.option.includes('선물세트')) {
            return -1;
          } else if (!a.option.includes('선물세트') && b.option.includes('선물세트')) {
            return 1;
          }

          // option 열에 "중국산"을 포함하면 아래로 이동
          if (a.option.includes('중국산') && !b.option.includes('중국산')) {
            return 1;
          } else if (!a.option.includes('중국산') && b.option.includes('중국산')) {
            return -1;
          }

          // '들기름', '참기름', '선물세트'가 모두 포함되거나 모두 미포함된 경우 가나다 순으로 정렬
          return a.option.localeCompare(b.option);
        });

          // 데이터 행 추가 (3행부터)
          sortedGroupedData.forEach((data, index) => {
            const currentRow = index + 3; // 3행부터 시작

            sheet.getCell(`G${currentRow}`).value = data.option; // option 열
            sheet.getCell(`H${currentRow}`).value = data.totalQuantity; // totalQuantity 열
          });

          // 특정 텍스트가 포함된 행을 찾아 배경색 변경
          sheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
            const cellGValue = row.getCell('G').value;

            // 특정 텍스트가 포함되어 있는지 확인
            if (cellGValue && typeof cellGValue === 'string' && (cellGValue.includes('들깨가루') || cellGValue.includes('미숫가루'))) {
              // 포함되어 있다면 셀 배경색 변경
              row.getCell('G').fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'DCE6F1' }, // 배경색 설정
              };
      
              row.getCell('H').fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'DCE6F1' }, // 배경색 설정
              };
            }
          });

          // 테두리 설정

          sheet.getCell('G2').border = { // '옵션' 제목 부분
            top: { style: 'medium', color: { argb: '000000' } }, // 상단 테두리
            bottom: { style: 'medium', color: { argb: '000000' } }, // 하단 테두리
            left: { style: 'medium', color: { argb: '000000' } }, // 좌측 테두리
          };

          sheet.getCell('H2').border = { // '수량' 제목 부분
            top: { style: 'medium', color: { argb: '000000' } }, // 상단 테두리
            bottom: { style: 'medium', color: { argb: '000000' } }, // 하단 테두리
            right: { style: 'medium', color: { argb: '000000' } }, // 우측 테두리
          };

          let lastNonEmptyRow = 0; // 마지막 행 얻기

          sheet.getColumn('G').eachCell({ reverse: true }, (cell, rowNumber) => {
            if (cell.value !== null && cell.value !== undefined) {
              lastNonEmptyRow = rowNumber;
              return false; // 순회 중지
            }
          });

          const borderStyle = { style: 'medium' };
          const start = {row: 3, col: 7}
          const end = {row: lastNonEmptyRow, col: 8}

          for (let i = start.row; i <= end.row; i++) {
              const leftBorderCell = sheet.getCell(i, start.col);
              const rightBorderCell = sheet.getCell(i, end.col);
              leftBorderCell.border = {
                  ...leftBorderCell.border,
                  left: borderStyle
              };
              rightBorderCell.border = {
                  ...rightBorderCell.border,
                  right: borderStyle
              };
          }

          for (let i = start.col; i <= end.col; i++) {
              const topBorderCell = sheet.getCell(start.row, i);
              const bottomBorderCell = sheet.getCell(end.row, i);
              topBorderCell.border = {
                  ...topBorderCell.border,
                  top: borderStyle
              };
              bottomBorderCell.border = {
                  ...bottomBorderCell.border,
                  bottom: borderStyle
              };
          }

          const targetColumn = 'G'; // 특정 열
          const adjacentColumn = 'H'; // 인접한 열
          
          // 특정 열의 각 행을 비교하여 값이 바뀌면 해당 셀의 위쪽에 테두리 추가
          let previousValue = null;
          
          sheet.getColumn(targetColumn).eachCell((cell, rowNumber) => {
            const currentValue = cell.value ? cell.value.toString().substring(0, 5) : '';
            const currentWholeValue = cell.value ? cell.value.toString() : ''; 
          
            if (!currentWholeValue.includes('선물세트') && (previousValue === null || previousValue !== currentValue)) {
              // 값이 바뀌면 해당 셀의 스타일 설정 가져오기
              const currentStyle = cell.style;
          
              // 모든 테두리 속성 가져오기
              const currentBorders = currentStyle.border || {};
              
              // top 속성 변경
              currentBorders.top = { style: 'medium', color: { argb: '000000' } };
          
              // 변경된 테두리 속성 전체 다시 설정
              currentStyle.border = currentBorders;
          
              // 변경된 스타일 설정 다시 적용
              cell.style = currentStyle;
          
              // H열까지의 셀에 대해도 테두리 설정
              for (let col = targetColumn.charCodeAt(0) + 1; col <= adjacentColumn.charCodeAt(0); col++) {
                const adjacentCell = sheet.getCell(String.fromCharCode(col) + rowNumber);
                const adjacentStyle = adjacentCell.style;
          
                // 모든 테두리 속성 가져오기
                const adjacentBorders = adjacentStyle.border || {};
                
                // top 속성 변경
                adjacentBorders.top = { style: 'medium', color: { argb: '000000' } };
          
                // 변경된 테두리 속성 전체 다시 설정
                adjacentStyle.border = adjacentBorders;
          
                // 변경된 스타일 설정 다시 적용
                adjacentCell.style = adjacentStyle;
              }
            }
          
            previousValue = currentValue;
          });

        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //                                                기름 모아보기                                               //
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        sheet.getCell('K2').value = '국참'
        sheet.getCell('L2').value = '국들'
        sheet.getCell('M2').value = '중참'
        sheet.getCell('N2').value = '중들'
        sheet.getCell('J3').value = '100mL'
        sheet.getCell('J4').value = '250mL'
        sheet.getCell('J5').value = '500mL'
        sheet.getCell('J6').value = '1L'

        sheet.getColumn('J').width = 12;

        for (let col = 10; col <= 14; col++) {
          const cell = sheet.getCell(2, col);
          cell.font = { bold: true };
          cell.alignment = { horizontal: 'center' };
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'CCCCFF' },
          };
          cell.border = { bottom: { style: 'medium', color: { argb: '000000' } } };
        }
        for (let row = 3; row <= 6; row++) {
          const cell = sheet.getCell(row, 10);
          cell.font = { bold: true };
          cell.alignment = { horizontal: 'center' };
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'CCCCFF' },
          };
          cell.border = { right: { style: 'medium', color: { argb: '000000' } } };
        }

        sheet.getCell('J2').border = {
          bottom: { style: 'medium', color: { argb: '000000' } },
          right: { style: 'medium', color: { argb: '000000' } }
        };

        for (let row = 3; row <= 6; row++) {
          for (let col = 11; col <= 14; col++) {
            const cell = sheet.getCell(row, col);
            cell.font = { bold: true };
            cell.alignment = { horizontal: 'center' };
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'E4DFEC' },
            };
          }
        }

        sheet.getCell('K3').value = getQuantityForOption(sortedGroupedData, '국산 참기름 100mL');
        sheet.getCell('L3').value = getQuantityForOption(sortedGroupedData, '국산 들기름 100mL');
        sheet.getCell('M3').value = getQuantityForOption(sortedGroupedData, '중국산 참기름 100mL');
        sheet.getCell('N3').value = getQuantityForOption(sortedGroupedData, '중국산 들기름 100mL');
        sheet.getCell('K6').value = getQuantityForOption(sortedGroupedData, '국산 참기름 1L');
        sheet.getCell('L6').value = getQuantityForOption(sortedGroupedData, '국산 들기름 1L');
        sheet.getCell('M6').value = getQuantityForOption(sortedGroupedData, '중국산 참기름 1L');
        sheet.getCell('N6').value = getQuantityForOption(sortedGroupedData, '중국산 들기름 1L');
        sheet.getCell('M4').value = getQuantityForOption(sortedGroupedData, '중국산 참기름 250mL');
        sheet.getCell('N4').value = getQuantityForOption(sortedGroupedData, '중국산 들기름 250mL');
        sheet.getCell('M5').value = getQuantityForOption(sortedGroupedData, '중국산 참기름 500mL');
        sheet.getCell('N5').value = getQuantityForOption(sortedGroupedData, '중국산 들기름 500mL');

        sheet.getCell('K4').value = getTotalQuantityForOptions(sortedGroupedData, ['국산 참기름 250mL', '국산 참기름 선물세트 250mL 1구', '국산 참기름 선물세트 250mL 2구', '국산 참기름 들기름 선물세트 250mL 2구']);
        sheet.getCell('L4').value = getTotalQuantityForOptions(sortedGroupedData, ['국산 들기름 250mL', '국산 들기름 선물세트 250mL 1구', '국산 들기름 선물세트 250mL 2구', '국산 참기름 들기름 선물세트 250mL 2구']);
        sheet.getCell('K5').value = getTotalQuantityForOptions(sortedGroupedData, ['국산 참기름 500mL', '국산 참기름 선물세트 500mL 1구', '국산 참기름 선물세트 500mL 2구', '국산 참기름 들기름 선물세트 500mL 2구']);
        sheet.getCell('L5').value = getTotalQuantityForOptions(sortedGroupedData, ['국산 들기름 500mL', '국산 들기름 선물세트 500mL 1구', '국산 들기름 선물세트 500mL 2구', '국산 참기름 들기름 선물세트 500mL 2구']);

        sheet.getCell('J8').value = '국산 합계'
        sheet.getCell('J9').value = '중국산 합계'

        sheet.getCell('J8').alignment = { horizontal: 'center' };
        sheet.getCell('J9').alignment = { horizontal: 'center' };

        for (let row = 8; row <= 9; row++) {
          const cell = sheet.getCell(row, 10);
          cell.font = { bold: true };
          cell.alignment = { horizontal: 'center' };
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'CCCCFF' },
          };
        }

        //sheet.getCell('K8').value = { formula: 'SUM(K3:L6)' };  이렇게 하면 제한된 보기에서 값이 안나옴, 그래서 무식하게 그냥 다 더해서 숫자로 입력
        //sheet.getCell('K9').value = { formula: 'SUM(M3:N6)' };

        sheet.getCell('K8').value = calculateSum(sheet, 3, 6, 'K', 'L')
        sheet.getCell('K9').value = calculateSum(sheet, 3, 6, 'M', 'N')

        sheet.getCell('K8').alignment = { horizontal: 'center' };
        sheet.getCell('K8').font = { bold: true };
        sheet.getCell('K9').alignment = { horizontal: 'center' };
        sheet.getCell('K9').font = { bold: true };

        // 가공이 완료된 파일 저장
        saveProcessedFile(workbook, 1);
      });
    };

    reader.readAsArrayBuffer(selectedFile1);
  };

        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //                                                택배양식 변환                                               //
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const parcelSheet = () => {
    if (!selectedFile1) {
      console.error('파일을 선택하세요.');
      alert('파일을 선택하세요.');
      return;
    }

    const originalWorkbook = new ExcelJS.Workbook();
    const reader = new FileReader();

    reader.onload = (e) => {
      const buffer = e.target.result;
      const data = new Uint8Array(buffer);
      originalWorkbook.xlsx.load(data).then(() => {

        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //                                      엑셀 파일에서 필요한 부분만 얻기                                      //
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        // 첫 번째 시트를 가져옴
        const worksheet = originalWorkbook.getWorksheet(1);

        // 필터를 해제
        worksheet.autoFilter = null

        // 1 행을 삭제
        worksheet.spliceRows(1, 1);

        // 필요한 열 제목들
        const requiredColumns = ["수취인명", "옵션정보", "수량", '수취인연락처1', '수취인연락처2', '통합배송지', '우편번호', '배송메세지'];

        // 열 제목을 가져옴
        let headerRow = worksheet.getRow(1);
        let columnNames = headerRow.values;

        // 역순으로 반복문을 수행하여 열 삭제
        for (let columnIndex = columnNames.length; columnIndex >= 1; columnIndex--) {
          const columnName = columnNames[columnIndex];
          
          if (!requiredColumns.includes(columnName)) {
            worksheet.spliceColumns(columnIndex, 1);
          }
        }

        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //                                        가공한 파일을 JSON으로 변환                                         //
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        const jsonData = [];
        
        let headers; // 헤더 변수 선언

        // 행을 처리 (2행부터)
        worksheet.eachRow({ includeEmpty: false }, (row, rowIndex) => {

          headers = ['name', 'option', 'num1', 'num2', 'address', 'postalNum', 'message'];

          if (rowIndex !== 1) {
            const rowData = {};
            rowData[headers[0]] = row.getCell(1).value
            rowData[headers[1]] = row.getCell(2).value + 'X' + row.getCell(3).value
            rowData[headers[2]] = row.getCell(4).value
            rowData[headers[3]] = row.getCell(5).value
            rowData[headers[4]] = row.getCell(6).value
            rowData[headers[5]] = row.getCell(7).value
            rowData[headers[6]] = row.getCell(8).value
            jsonData.push(rowData);

          }
        });

        // 'option' 키 불필요한 내용 제거
        jsonData.forEach((data) => {
          if (data.option.includes(':')) {
            // 콜론(:)이 있는 경우에만 수정
            const optionParts = data.option.split(': ');
            if (optionParts.length > 1) {
              // 콜론 이후의 부분이 있는 경우에만 수정
              const afterColon = optionParts[1].trim();
              // 맨 앞에 '♣'을 붙여서 수정
              data.option = `♣${afterColon}`;
            }
          }
        });

        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //                                           새로운 엑셀 파일 작성                                            //
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        const workbook = new Workbook();
        const sheet = workbook.addWorksheet();

        // Header 설정
        sheet.getRow(1).values = ['받는분성명', '받는분전화번호', '받는분기타연락처', '받는분우편번호', '받는분주소(전체,분할)', '품목명', '배송메세지1'];
        for (let col = 1; col <= 7; col++) {
          const cell = sheet.getCell(1, col);
          cell.font = { bold: true };
          cell.alignment = { horizontal: 'center' };
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'CCCCFF' },
          };
        }

        sheet.columns = [
          { key: 'name'},
          { key: 'num1'},
          { key: 'num2'},
          { key: 'postalNum'},
          { key: 'address'},
          { key: 'option'},
          { key: 'message'},
        ]

        sheet.addRows(jsonData);

        // 열 너비
        sheet.getColumn('A').width = 11;
        sheet.getColumn('B').width = 15;
        sheet.getColumn('C').width = 15;
        sheet.getColumn('D').width = 10;
        sheet.getColumn('E').width = 100;
        sheet.getColumn('F').width = 30;
        sheet.getColumn('G').width = 50;

        // 가공이 완료된 파일 저장
        saveProcessedFile(workbook, 2);
      });
    };

    reader.readAsArrayBuffer(selectedFile1);
  };

        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //                                             일괄배송 양식 변환                                             //
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const trackingSheet = () => {
    if (!selectedFile1) {
      console.error('파일을 선택하세요.');
      alert('파일을 선택하세요.');
      return;
    }

    if (!selectedFile2) {
      console.error('파일을 선택하세요.');
      alert('파일을 선택하세요.');
      return;
    }

    const reader1 = new FileReader();
    const reader2 = new FileReader();

    const file1Promise = new Promise((resolve) => {
      reader1.onload = (e) => {
        resolve(e.target.result);
      };
    });

    const file2Promise = new Promise((resolve) => {
      reader2.onload = (e) => {
        resolve(e.target.result);
      };
    });

    // 파일1과 파일2를 동시에 로딩
    reader1.readAsArrayBuffer(selectedFile1);
    reader2.readAsArrayBuffer(selectedFile2);

    // 두 파일이 모두 로딩되었을 때의 작업
    Promise.all([file1Promise, file2Promise]).then((results) => {
      const [file1Data, file2Data] = results;

      const originalWorkbook1 = new ExcelJS.Workbook();
      const originalWorkbook2 = new ExcelJS.Workbook();

      // file1Data와 file2Data를 이용한 작업
      originalWorkbook1.xlsx.load(file1Data).then(() => {
        originalWorkbook2.xlsx.load(file2Data).then(() => {

          ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //                                      엑셀 파일에서 필요한 부분만 얻기                                      //
          ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

          // 첫 번째 시트를 가져옴
          const worksheet1 = originalWorkbook1.getWorksheet(1);
          const worksheet2 = originalWorkbook2.getWorksheet(1);

          // 필터를 해제
          worksheet1.autoFilter = null

          // 1 행을 삭제
          worksheet1.spliceRows(1, 1);
          worksheet2.spliceRows(1, 3);

          // 필요한 열 제목들
          const requiredColumns1 = ["상품주문번호", "배송방법", "수취인명", "수취인연락처1"];
          const requiredColumns2 = ["운송장번호", "수하인명", "수하인휴대폰"];

          // 열 제목을 가져옴
          let headerRow1 = worksheet1.getRow(1);
          let columnNames1 = headerRow1.values;
          let headerRow2 = worksheet2.getRow(1);
          let columnNames2 = headerRow2.values;

          // 역순으로 반복문을 수행하여 열 삭제
          for (let columnIndex = columnNames1.length; columnIndex >= 1; columnIndex--) {
            const columnName1 = columnNames1[columnIndex];
            
            if (!requiredColumns1.includes(columnName1)) {
              worksheet1.spliceColumns(columnIndex, 1);
            }
          }

          for (let columnIndex = columnNames2.length; columnIndex >= 1; columnIndex--) {
            const columnName2 = columnNames2[columnIndex];
            
            if (!requiredColumns2.includes(columnName2)) {
              worksheet2.spliceColumns(columnIndex, 1);
            }
          }

          ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //                                        가공한 파일을 JSON으로 변환                                         //
          ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

          const jsonData = [];

          let headers; // 헤더 변수 선언
          headers = ['상품주문번호', '배송방법', '택배사', '송장번호'];

          // 행을 처리 (2행부터)
          worksheet1.eachRow({ includeEmpty: false }, (row1, rowIndex1) => {

            if ( rowIndex1 !== 1 ) {
              const rowData = {};
              rowData[headers[0]] = row1.getCell(1).value;
              rowData[headers[1]] = row1.getCell(2).value;
              rowData[headers[2]] = '로젠택배';

              const sheet1Name = row1.getCell(3).value;
              const sheet1Phone = row1.getCell(4).value.slice(0, -3).replace(/-/g, '');

              worksheet2.eachRow({ includeEmpty: false }, (row2, rowIndex2) => {
                if ( rowIndex2 !== 1 ) {
                  const sheet2Name = row2.getCell(2).value;
                  let sheet2Phone = row2.getCell(3).value;

                  // sheet2Phone이 null인지 확인
                  if (sheet2Phone !== null && sheet2Phone !== undefined) {
                    sheet2Phone = sheet2Phone.replace(/-/g, '').slice(0, -3);
                  } else {
                    sheet2Phone = ''; // null인 경우 빈 문자열로 처리
                  }
                  
                  if ( sheet1Name === sheet2Name && sheet1Phone === sheet2Phone ) {
                    rowData[headers[3]] = parseInt(row2.getCell(1).value);
                    return false;
                  };
                };
              });

              jsonData.push(rowData);
            }
          });


          ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //                                           새로운 엑셀 파일 작성                                            //
          ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

          const xlsx = require('xlsx-js-style');
          // Create a new workbook
          const ws = xlsx.utils.json_to_sheet(jsonData);
          const wb = xlsx.utils.book_new();

          ws['!cols'] = [
            {wch : 20},
            {wch : 20},
            {wch : 20},
            {wch : 20},
            ];

          xlsx.utils.book_append_sheet(wb, ws, '발송처리');

          const currentDate = new Date();
          const year = String(currentDate.getFullYear()).slice(-2);
          const month = String(currentDate.getMonth() + 1).padStart(2, '0');
          const day = String(currentDate.getDate()).padStart(2, '0');
          const dateString = `${year}${month}${day}`;
        
          // Convert workbook to array buffer
          const buffer = xlsx.write(wb, { bookType: 'biff8', type: 'buffer' });

          // Create a Blob with the desired MIME type
          const blob = new Blob([buffer], { type: 'application/vnd.ms-excel' });
        
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.href = url;
          a.download = `3) ${dateString}_일괄입력_스마트스토어.xls`;
        
          a.click();
        
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);


        });
      });
    });

  };

  const saveProcessedFile = (workbook, type) => {
    // 현재 날짜를 얻어와서 파일 이름으로 사용 (YYMMDD 형식)
    const currentDate = new Date();
    const year = String(currentDate.getFullYear()).slice(-2);
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const dateString = `${year}${month}${day}`;

    // Blob 생성
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // 브라우저에서 파일을 다운로드하는 링크 생성
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.href = url;

      // 현재 날짜를 파일 이름에 추가
      if ( type === 1 ) {
        a.download = `1) ${dateString}_스마트스토어주문서.xlsx`;
      } else if ( type === 2 ) {
        a.download = `2) ${dateString}_택배양식(스마트 to 로젠).xlsx`;
      } else if ( type === 3) {
        a.download = `3) ${dateString}_일괄입력_스마트스토어.xlsx`;
      }
      

      // 링크 클릭 및 다운로드 시작
      a.click();

      // 다운로드 후 링크 및 Blob 해제
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    });
  };

  return (
    <div class="noScroll">

      <div class="row">

      <p class="navBarFont">
        <Link to="/" class="navBarFont">GIstock</Link>
        <Link to="/" class="navBarFont2">메인</Link>
        <Link to="/SayongbangbubPage" class="navBarFont3">사용방법</Link>
        <Link to="/GeabaljaPage" class="navBarFont4">개발자</Link>
      </p>

      </div> 

      <div>
        <div class="container-fluid">

          <div class="row">
            <div class="col bg-warning-subtle">
              <span class="myImage">
                <img src="harvest.jpg" class="img-fluid2" alt="..."></img>
              </span>
            </div>

            <div class="col bg-warning-subtle">
              <div class="row"> 
                <div class="row">
                  <p class="yangsicExchange">『 주문서, 택배양식 변환 』</p>
                </div>
                <div class="row">
                  <div class="col">
                      <p class="mb-3">
                        <input class="form-control" type="file" id="formFile" accept=".xls, .xlsx" onChange={handleFileChange1}/>
                      </p>
                      <p class="fileUpload">주문서 엑셀파일 업로드</p>
                  </div>
                  <div class="col">
                    <p class="myBtn"><button type="button" class="btn btn-outline-warning" onClick={orderSheet}>주문서 변환</button></p>
                    <p class="myBtn"><button type="button" class="btn btn-outline-info" onClick={parcelSheet}>택배양식 변환</button></p>
                  </div>
                </div>
              </div>

              <div class="row"> 
                <div class="row">
                  <p class="yangsicExchange">『 일괄배송 양식 변환 』</p>
                </div>
                <div class="row">
                  <div class="col">
                      <p class="mb-3">
                        <input class="form-control" type="file" id="formFile" accept=".xls, .xlsx" onChange={handleFileChange2}/>
                      </p>
                      <p class="fileUpload">로젠택배 송장파일 업로드</p>
                  </div>
                  <div class="col">
                    <p class="myBtn"><button type="button" class="btn btn-outline-success" onClick={trackingSheet}>일괄배송 양식 변환</button></p>
                  </div>

                </div>
              </div>

            </div>
          </div>

        </div>
      </div>

    </div>

  )
}

// 특정 option의 quantity 값을 얻는 함수
function getQuantityForOption(data, targetOption) {
  // find 메서드를 사용하여 특정 option을 찾고 해당 option의 quantity를 반환
  const foundItem = data.find(item => item.option === targetOption);

  // 찾은 경우에만 quantity 값을 반환, 없는 경우는 0을 반환하거나 다른 처리를 할 수 있습니다.
  return foundItem ? parseInt(foundItem.totalQuantity) : 0;
}

// 여러 option의 totalQuantity 합을 얻는 함수
function getTotalQuantityForOptions(data, targetOptions) {
  // targetOptions 배열에 포함된 각 option의 totalQuantity를 더하여 합을 계산
  const totalQuantity = targetOptions.reduce((sum, targetOption) => {
    // find 메서드를 사용하여 각 option을 찾음
    const foundItem = data.find(item => item.option.includes(targetOption));

    // 찾은 경우에만 해당 option의 totalQuantity를 더함
    if (foundItem) {
      if (targetOption === '국산 참기름 선물세트 250mL 2구' || targetOption === '국산 들기름 선물세트 250mL 2구' || targetOption === '국산 참기름 선물세트 500mL 2구' || targetOption === '국산 들기름 선물세트 500mL 2구') {
        sum += foundItem.totalQuantity * 2 || 0;
      } else {
        sum += foundItem.totalQuantity || 0;
      }
    }

    return sum;
  }, 0);

  return totalQuantity;
}

// 더한 결과 계산 함수
function calculateSum(sheet, startRow, endRow, startColumn, endColumn) {
  const cellsToSum = [];

  for (let row = startRow; row <= endRow; row++) {
    for (let col = startColumn.charCodeAt(0); col <= endColumn.charCodeAt(0); col++) {
      const cell = sheet.getCell(String.fromCharCode(col) + row);
      cellsToSum.push(cell.value || 0); // 값이 없으면 0으로 처리
    }
  }

  return cellsToSum.reduce((total, value) => total + value, 0);
};

export default Home